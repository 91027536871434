import { postData, querySearch } from '@utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRoles() {
      // return new Promise((resolve, reject) => {
      //   axios
      //     .get('/roles')
      //     .then(response => resolve(response))
      //     .catch(error => reject(error))
      // })
    },

    fetchPricelist() {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getPriceLists',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchCustomerGroup() {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getMemberGroups',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchRole() {
      return new Promise((resolve, reject) => {
        postData({
          method: 'getProfileList',
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        postData(querySearch(queryParams, 'getMembers'))
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    fetchUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        postData(userData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        postData(userData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        postData(userData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
